import React from "react";
import "./home.css";
import { Link } from "react-router-dom";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Treatments from "../../Components/Treatments/treatments";
import Testimonials from "../../Components/Testimonials/testimonials";
import Bannerimg from "../../Assets/bannerimage.png";
import { FaArrowRightLong } from "react-icons/fa6";
import Hygiene from "../../Assets/hygienestand.svg";
import Treatment from "../../Assets/treatmentcare.svg";
import Smile from "../../Assets/googlesmile.svg";
import Google from "../../Assets/googlerate.png";
import Drsneha from "../../Assets/drsneha.jpg";

export default function Home(){
    return(
        <React.Fragment>
            <Header/>
            <section className="bannerarea">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-7">
                            <div className="bannercontent">
                                <h2>Where Your Brightest Smile Begins</h2>
                                <p>Let us help you achieve a healthy and beautiful smile that you truly deserve. Give us a call to know more.</p>
                                <div className="bannerbtns">
                                    <Link to="#">request appointment <FaArrowRightLong/></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="bannerimagearea">
                                <div className="bannerimg">
                                    <img src={Bannerimg} alt="bannerimg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="trustse">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4">
                            <div className="trustgrid">
                                <div className="tursticon"><img src={Hygiene} alt="Exceptional Hygiene Standards" /></div>
                                <div className="trustcontent">
                                    <h4>Exceptional Hygiene Standards</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4">
                            <div className="trustgrid">
                            <div className="tursticon"><img src={Treatment} alt="Personalised Treatment & Care" /></div>
                                <div className="trustcontent">
                                    <h4>Personalised Treatment & Care</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4">
                            <div className="trustgrid">
                            <div className="tursticon"><img src={Smile} alt="Google smile" /></div>
                                <div className="trustcontent">
                                    <img src={Google} alt="Google Rating" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Treatments/>
            <Testimonials/>
            <section className="qualified">
                <div className="container">
                    <div className="doctorheaing">
                        <h2>Our Highly Qualified Dentist</h2>
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center justify-content-center g-0">
                        <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="doctorimg">
                                <img src={Drsneha} alt="Dr Sneha Bharuka Panikar" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-8 col-sm-12 col-xs-12 col-12">
                            <div className="doctorcontent">
                                <h2>Dr Sneha Bharuka Panikar</h2>
                                <small>Principal Dentist</small>
                                <p>Dr Sneha did her post graduation in the specialty field of Paediatric and Preventive Dentistry, batch of 2013, from the prestigious Rajiv Gandhi university of Health Sciences and has since been the practice owner and the primary pedodontist, at Panikar’s Centre for Advanced Dentistry.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </React.Fragment>
    )
}
