import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import "./header.css";
import { FaBars, FaAngleUp } from "react-icons/fa6";
import Mainlogo from "../../Assets/mainlogo.png"

export default function Header(){
    useEffect(() => {
        const header = document.querySelector('.mainheaderarea');
        const sticky = header.offsetTop;
        const handleScroll = () => {
            if (window.scrollY > sticky) {
                header.classList.add('sticky');
            } else {
                header.classList.remove('sticky');
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return(
        <React.Fragment>
            <header className="mainheaderarea">
                <div className="container">
                    <div className="headerarea">
                        <div className="headerlogo">
                            <Link to="/"><img src={Mainlogo} alt="Main Logo"/></Link>
                        </div>
                        <div className="headermenu desktopmenu">
                            <ul>
                                <li><Link to="#">Home</Link></li>
                                <li className="haschild"><Link to="#">Treatments</Link> <FaAngleUp/>
                                    <ul className="has-submenu">
                                        <li><Link to="/dental-implants/">Dental Implants</Link></li>
                                        <li><Link to="/teeth-alignment/">Teeth alignment</Link></li>
                                        <li><Link to="/full-mouth-rehabilitation/">Full mouth rehabilitation</Link></li>
                                        <li><Link to="/smile-enhancement/">Smile enhancement</Link></li>
                                        <li><Link to="/preventive-dentistry/">Preventive dentistry</Link></li>
                                        <li><Link to="/general-dentistry/">General dentistry</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/smile-gallery/">Smile Gallery</Link></li>
                                <li><Link to="/why-choose-us/">Why Choose Us</Link></li>
                                <li className="booknowbtns"><Link to="#">Book Now</Link></li>
                                <li className="menutoggle haschild"><FaBars/>
                                    <ul className="has-submenu">
                                        <li><Link to="/contact-us/">Contact Us</Link></li>
                                        <li><Link to="/faqs/">FAQ’s</Link></li>
                                        <li><Link to="/blogs/">Blogs</Link></li>
                                        <li><Link to="/privacy-policy/">Privacy policy</Link></li>
                                        <li><Link to="/location/">Location</Link></li>
                                        <li><Link to="/terms-conditions/">T&Cs</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    )
}