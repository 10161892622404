import React, {useState} from "react";
import "./smilegallery.css";
import { Link } from "react-router-dom";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Gallery1 from "../../Assets/gallerybefore.jpg"
import Gallery2 from "../../Assets/galleryafter.jpg"
import { FaStar } from "react-icons/fa";
import Sliders from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Testimonialimg from "../../Assets/testimonialimg.jpg"
import { Modal, Button } from 'react-bootstrap';
import YouTube from 'react-youtube';
import { FaRegCirclePlay } from "react-icons/fa6";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

const testisetting = {
    dots: false,
    infinite: true,
    speed: 200,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 3,
    slidesToScroll: 1,
    pauseOnHover: false,
    arrows: true,
    nextArrow: (
        <button type="button" className="nextslider">
          <FaArrowRightLong style={{ fontSize: '24px' }} />
        </button>
    ),
    prevArrow: (
        <button type="button" className="prevslider">
          <FaArrowLeftLong style={{ fontSize: '24px' }} />
        </button>
    ),
    responsive: [
      {
          breakpoint: 800,
          settings: {
            slidesToShow: 3,
          },
      },
      {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
      },
    ]
};
export default function SmileGallery(){
    const [isOpen, setIsOpen] = useState(false);
    const [videoId, setVideoId] = useState('');
    const handleClose = () => setIsOpen(false);
    const openModal = (id) => {
        setVideoId(id);
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
        setVideoId('');
    };
    const opts = {
        height: '500px',
        width: '100%',
        playerVars: {
            autoplay: 1, 
            rel: 0, 
        },
    };
    return(
        <React.Fragment>
            <Header/>
                <section className="breadcrumbarea">
                    <div className="container">
                        <div className="breadhead">
                            <h1>Smile Gallery</h1>
                        </div>
                        <div className="breadcrumblist">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>Smile Gallery</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="smiling">
                    <div className="container">
                        <div className="smilinghead">
                            <h2>Smiling Patients, Happy Hearts</h2>
                            <p>See what our patients have to say about their experience with us. We take pride in creating beautiful smiles and lasting relationships through exceptional care.</p>
                        </div>
                        <div className="container">
                            <div className="smilegallery">
                                <div className="smilegalleryhed">
                                    <h3>Treatment name - Invisalign</h3>
                                </div>
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-lg-4">
                                        <img src={Gallery1} alt="Before" />
                                        <p>Before</p>
                                    </div>
                                    <div className="col-lg-4">
                                        <img src={Gallery2} alt="After" />
                                        <p>After</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="smilingpate">
                    <div className="container">
                        <div className="smilingpatehead">
                            <h2>Smiling Patients, Happy Hearts</h2>
                            <p>See what our patients have to say about their experience with us. We take pride in creating beautiful smiles and lasting relationships through exceptional care.</p>
                        </div>
                    </div>
                    <div className="container">
                        <Sliders {...testisetting}>
                            <div className="testimonialsgrids">
                                <div className="testimonialname">
                                    <h3>Deepika Salunkhe</h3>
                                    {[...Array(5)].map((_, index) => (
                                        <FaStar key={index} />
                                    ))}
                                </div>
                                <div className="testimonialcontent">
                                    <p>Excellent doctor and attend the My little kids carefully... Happy the way she treated my 3 year old child... Thank you doc & staff</p>
                                </div>
                            </div>
                            <div className="testimonialsgrids">
                                <div className="testimonialname">
                                    <h3>Shreeya Jambhale</h3>
                                    {[...Array(5)].map((_, index) => (
                                        <FaStar key={index} />
                                    ))}
                                </div>
                                <div className="testimonialcontent">
                                    <p>My child was having extreme pain and I consulted Dr Sneha and she did the consultation very gently and made my kid feel very comfortable and did emergency treatment. Later she advised treatment under general anaesthesia at hospital and operation went very smoothly and my kid is now doing perfect! She gave a very beautiful smile to my kid, fixed all
                                    his teeth.</p>
                                </div>
                            </div>
                            <div className="testimonialsgrids">
                                <div className="testimonialname">
                                    <h3>Shyamsundar</h3>
                                    {[...Array(5)].map((_, index) => (
                                        <FaStar key={index} />
                                    ))}
                                </div>
                                <div className="testimonialvideo">
                                    <img src={Testimonialimg} alt="" />
                                    <div className="videobtns" onClick={() => openModal('D_R2cGyeV9U')}><FaRegCirclePlay/></div>
                                </div>
                            </div>
                        </Sliders>
                    </div>
                </section>
                <Modal show={isOpen} onHide={closeModal} centered size="lg" dialogClassName="video-popup">
                <Modal.Body>
                    <div className="videopopupclose">
                        <Button variant="close" onClick={handleClose} aria-label="Close"></Button>
                    </div>
                    {videoId && <YouTube videoId={videoId} opts={opts} />}
                </Modal.Body>
            </Modal>
            <Footer/>
        </React.Fragment>
    )
}