import React from "react";
import "./blogs.css";
import { Link } from "react-router-dom";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Sliders from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import Blog1 from "../../Assets/blog.jpg";

const Blogsettings = {
    dots: false,
    infinite: true,
    speed: 200,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 3,
    slidesToScroll: 1,
    pauseOnHover: false,
    arrows: true,
    nextArrow: (
        <button type="button" className="nextslider">
          <FaArrowRightLong style={{ fontSize: '24px' }} />
        </button>
    ),
    prevArrow: (
        <button type="button" className="prevslider">
          <FaArrowLeftLong style={{ fontSize: '24px' }} />
        </button>
    ),
    responsive: [
      {
          breakpoint: 800,
          settings: {
            slidesToShow: 3,
          },
      },
      {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
      },
    ]
};
export default function Blogs(){
    return(
        <React.Fragment>
            <Header/>
                <section className="breadcrumbarea">
                    <div className="container">
                        <div className="breadhead">
                            <h1>Blogs</h1>
                        </div>
                        <div className="breadcrumblist">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>Blogs</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="blogpage">
                    <div className="container">
                        <div className="blogpcategory">
                            <ul>
                                <li className="active"><Link to="/blogs/">All</Link></li>
                                <li><Link to="#">General Dentistry</Link></li>
                                <li><Link to="#">Dental Implants</Link></li>
                                <li><Link to="#">Smile Makeover</Link></li>
                                <li><Link to="#">Preventive Dentistry</Link></li>
                            </ul>
                        </div>
                        <div className="bloglistings">
                            <Sliders {...Blogsettings}>
                                <div className="bloggrid">
                                    <div className="blogimg">
                                        <Link to="#"><img src={Blog1} alt="" /></Link>
                                    </div>
                                    <div className="blogcategory">
                                        <Link to="#">General Dentistry</Link>
                                    </div>
                                    <div className="blogtitle">
                                        <h2><Link to="#">Care of your Teeth</Link></h2>
                                    </div>
                                    <div className="blogcontent">
                                        <p>Lorem ipsum dolor sit amet consectetur.</p>
                                    </div>
                                </div>
                                <div className="bloggrid">
                                    <div className="blogimg">
                                        <Link to="#"><img src={Blog1} alt="" /></Link>
                                    </div>
                                    <div className="blogcategory">
                                        <Link to="#">General Dentistry</Link>
                                    </div>
                                    <div className="blogtitle">
                                        <h2><Link to="#">Care of your Teeth</Link></h2>
                                    </div>
                                    <div className="blogcontent">
                                        <p>Lorem ipsum dolor sit amet consectetur.</p>
                                    </div>
                                </div>
                                <div className="bloggrid">
                                    <div className="blogimg">
                                        <Link to="#"><img src={Blog1} alt="" /></Link>
                                    </div>
                                    <div className="blogcategory">
                                        <Link to="#">General Dentistry</Link>
                                    </div>
                                    <div className="blogtitle">
                                        <h2><Link to="#">Care of your Teeth</Link></h2>
                                    </div>
                                    <div className="blogcontent">
                                        <p>Lorem ipsum dolor sit amet consectetur.</p>
                                    </div>
                                </div>
                            </Sliders>
                        </div>
                    </div>
                </section>
            <Footer/>
        </React.Fragment>
    )
}