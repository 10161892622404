import React, {useState} from "react";
import { Link } from "react-router-dom";
import "./testimonials.css";
import Testimonialimg from "../../Assets/testimonialimg.jpg"
import { Modal, Button } from 'react-bootstrap';
import YouTube from 'react-youtube';
import { FaRegCirclePlay } from "react-icons/fa6";

export default function Testimonials(){
    const [isOpen, setIsOpen] = useState(false);
    const [videoId, setVideoId] = useState('');
    const handleClose = () => setIsOpen(false);
    const openModal = (id) => {
        setVideoId(id);
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
        setVideoId('');
    };
    const opts = {
        height: '500px',
        width: '100%',
        playerVars: {
            autoplay: 1, 
            rel: 0, 
        },
    };
    return(
        <React.Fragment>
            <section className="testimonials">
                <div className="container">
                    <div className="testimonialheader">
                        <h2>Real Smiles, Real Stories</h2>
                        <p>Discover the life-changing transformations our patients have experienced. Their smiles are our success stories—proof of the exceptional care and advanced technology we provide.</p>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="testimonialvideo">
                                <img src={Testimonialimg} alt="testimonails" />
                                <div className="videobtns" onClick={() => openModal('D_R2cGyeV9U')}><FaRegCirclePlay/></div>
                            </div>
                        </div>
                    </div>
                    <div className="readmoretesti">
                        <Link to="#">Read more testimonials</Link>
                    </div>
                </div>
            </section>
            <Modal show={isOpen} onHide={closeModal} centered size="lg" dialogClassName="video-popup">
                <Modal.Body>
                    <div className="videopopupclose">
                        <Button variant="close" onClick={handleClose} aria-label="Close"></Button>
                    </div>
                    {videoId && <YouTube videoId={videoId} opts={opts} />}
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}