import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./footer.css";
import Mainlogo from "../../Assets/mainlogo.png";
import { FaFacebookF, FaInstagram, FaPhoneVolume, FaEnvelopeOpenText, FaLocationDot } from "react-icons/fa6";
import Blogs from "../Blogs/blogs";

export default function Footer(){
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
    const secondSegment = pathSegments[0] || '';
    const isBlogPage = secondSegment === 'blogs';
    return(
        <React.Fragment>
            {!isBlogPage && (
                <Blogs/>
            )}
            <section className="footercontactform">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                            <div className="footerformcontn">
                                <h2>Leave your worries at the door and enjoy a healthier, more precise smile</h2>
                                <p>Ready to take the next step towards your perfect smile? Reach out to us today our team is here to answer your questions and schedule your personalized consultation.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                            <div className="footerform">
                                <h2>Request An Appointment</h2>
                                <form action="" method="post">
                                    <input type="text" className="form-control" placeholder="Full Name" />
                                    <input type="text" className="form-control" placeholder="Phone Number" />
                                    <input type="text" className="form-control" placeholder="Email Address" />
                                    <button type="submit">Submit</button>
                                </form>
                            </div>
                        </div>  
                    </div>
                </div>
            </section>
            <footer className="mainfooter">
                <div className="container">
                    <div className="contactinfo">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                                <div className="contactdatas">
                                    <div className="contacticons"><FaPhoneVolume/></div>
                                    <div className="contactdaa">
                                        <p>Call Today</p>
                                        <p><Link to="tel:+919004366342">+919004366342</Link></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                                <div className="contactdatas">
                                    <div className="contacticons"><FaEnvelopeOpenText/></div>
                                    <div className="contactdaa">
                                        <p>Email Us</p>
                                        <p><Link to="mailto:panikarsdentistry@gmail.com">panikarsdentistry@gmail.com</Link></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                                <div className="contactdatas">
                                    <div className="contacticons"><FaLocationDot/></div>
                                    <div className="contactdaa">
                                        <p>#13, Shree Ramtanu Mauli CHSL,</p>
                                        <p>Plot no 98, sector 1, Sanpada,</p>
                                        <p>Navi Mumbai, Maharashtra-400705</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                            <div className="footerlogo">
                                <Link to="/"><img src={Mainlogo} alt=""/></Link>
                            </div>
                            <div className="footercontnet">
                                <p>Let us help you achieve a healthy and beautiful smile that you truly deserve. Give us a call to know more.</p>
                            </div>
                            <div className="footermenu">
                                <div className="footermenuleft">
                                    <h2>Company</h2>
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/about-us/">About Us</Link></li>
                                        <li><Link to="/services/">Services</Link></li>
                                        <li><Link to="/contact-us/">Contact Us</Link></li>
                                    </ul>
                                </div>
                                <div className="footermenuleft">
                                    <h2>Treatments</h2>
                                    <ul>
                                        <li><Link to="/dental-care/">Dental Care</Link></li>
                                        <li><Link to="/oral-surgery/">Oral Surgery</Link></li>
                                        <li><Link to="/oral-hygienist/">Oral Hygienist</Link></li>
                                        <li><Link to="/teeth-cleaning/">Teeth Cleaning</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12 col-12">
                            <div className="footermaps">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.0036735544345!2d73.0045751!3d19.063576100000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c14ff90b878d%3A0x881a76abb24c2dd3!2sPanikar&#39;s%20Dentistry!5e0!3m2!1sen!2sin!4v1727786493506!5m2!1sen!2sin" title="map" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <section className="footterbotm">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-9">
                            <div className="copyrighttext">
                                <p>© 2024 Copyright Panikar’s dentistry. All Rights Reserved.| Website designed & developed by <Link to="https://www.ninetriangles.com/" target="_blank">Nine Triangles</Link></p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="footersocialmedia">
                                <ul>
                                    <li><Link to="https://www.facebook.com/Panikarsdentistry/"><FaFacebookF/></Link></li>
                                    <li><Link to="https://www.instagram.com/panikars_dentistry/"><FaInstagram/></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
