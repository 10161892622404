import React from "react";
import "./contactus.css";
import { Link } from "react-router-dom";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import { FaRegClock, FaEnvelopeOpenText, FaPhone, FaWhatsapp } from "react-icons/fa6";


export default function Contactus(){
    return(
        <React.Fragment>
            <Header/>
                <section className="breadcrumbarea">
                    <div className="container">
                        <div className="breadhead">
                            <h1>Contact Us</h1>
                        </div>
                        <div className="breadcrumblist">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>Contact Us</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="contactuspage">
                    <div className="container">
                        <div className="contactushea">
                            <h2>Get in touch</h2>
                            <p>Book an Appointment to treat your teeth right now.</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="getintouchdata">
                                    <div className="footermaps">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.0036735544345!2d73.0045751!3d19.063576100000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c14ff90b878d%3A0x881a76abb24c2dd3!2sPanikar&#39;s%20Dentistry!5e0!3m2!1sen!2sin!4v1727786493506!5m2!1sen!2sin" title="map" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                    <div className="addressdetais">
                                        <div className="addresslist">
                                            <div className="addressicon"><FaRegClock/></div>
                                            <div className="addresscontent">
                                                <h3>Office Timings</h3>
                                                <p>Monday - Saturday (9:00am to 5pm)</p>
                                                <p>Sunday (Closed)</p>
                                            </div>
                                        </div>
                                        <div className="addresslist">
                                            <div className="addressicon"><FaEnvelopeOpenText/></div>
                                            <div className="addresscontent">
                                                <h3>Emai Address</h3>
                                                <p><Link to="mailto:dr.spanikar@gmail.com">dr.spanikar@gmail.com</Link></p>
                                            </div>
                                        </div>
                                        <div className="addresslist">
                                            <div className="addressicon"><FaPhone/></div>
                                            <div className="addresscontent">
                                                <h3>Phone Number</h3>
                                                <p><Link to="tel:9004308071">9004308071</Link></p>
                                            </div>
                                        </div>
                                        <div className="addresslist">
                                            <div className="addressicon"><FaWhatsapp/></div>
                                            <div className="addresscontent">
                                                <h3>Live chat</h3>
                                                <p><Link to="https://wa.me/919004308071/" target="_blank">9004308071</Link></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="contactformpage">
                                    <form action="#" method="post">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="first_name">First Name</label>
                                                    <input type="text" name="first_name" id="first_name" class="form-control" placeholder="First Name" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="last_name">Last Name</label>
                                                    <input type="text" name="last_name" id="last_name" class="form-control" placeholder="Last Name" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label htmlFor="email">Email</label>
                                                    <input type="email" name="email" id="email" class="form-control" placeholder="you@company.com" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label htmlFor="phone">Phone number</label>
                                                    <input type="tel" name="phone" id="phone" class="form-control" placeholder="+91 00000 00000" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label htmlFor="date">Select date</label>
                                                    <input type="date" name="date" id="date" class="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label htmlFor="services">Select Service</label>
                                                    <select name="services" id="services" className="form-select">
                                                        <option value="">Select Serivce</option>
                                                        <option value="">Service 1</option>
                                                        <option value="">Service 2</option>
                                                        <option value="">Service 3</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="formsubmit">
                                                    <button type="submit">Book an appointment</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer/>
        </React.Fragment>
    )
}