import React from "react";
import "./faqs.css";
import { Link } from "react-router-dom";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Accordion from 'react-bootstrap/Accordion';


export default function Faqs(){
    return(
        <React.Fragment>
            <Header/>
                <section className="breadcrumbarea">
                    <div className="container">
                        <div className="breadhead">
                            <h1>FAQ's</h1>
                        </div>
                        <div className="breadcrumblist">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>FAQ's</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="faqsp">
                    <div className="container">
                        <div className="faqsheader">
                            <h2>FAQ’s</h2>
                            <p>Everything you need to know</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="faqsdata">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are dental implants?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Dental implants are artificial tooth roots made of titanium, used to support replacement teeth like crowns or bridges,
                                        mimicking natural teeth.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Who can get dental implants?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Dental implants are artificial tooth roots made of titanium, used to support replacement teeth like crowns or bridges,
                                        mimicking natural teeth.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Is the procedure painful?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Dental implants are artificial tooth roots made of titanium, used to support replacement teeth like crowns or bridges, mimicking natural teeth.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>How long do dental implants last?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Dental implants are artificial tooth roots made of titanium, used to support replacement teeth like crowns or bridges, mimicking natural teeth.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>How do I care for dental implants?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Dental implants are artificial tooth roots made of titanium, used to support replacement teeth like crowns or bridges, mimicking natural teeth.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </section>
            <Footer/>
        </React.Fragment>
    )
}