import React from "react";
import "./whychoose.css";
import { Link } from "react-router-dom";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Practice from "../../Assets/practice.jpg";
import Sreekumar from "../../Assets/Sreekumar.jpg"
import Sneha from "../../Assets/sneha.jpg"
import Technologies from "../../Assets/technologies.jpg"


export default function WhyChoose(){
    return(
        <React.Fragment>
            <Header/>
                <section className="breadcrumbarea">
                    <div className="container">
                        <div className="breadhead">
                            <h1>Why Choose Us</h1>
                        </div>
                        <div className="breadcrumblist">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>Why Choose Us</li>
                            </ul>
                        </div>
                    </div>
                </section>
               <section className="practice">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6">
                                <div className="practiceleft">
                                    <div className="practicehed">
                                        <h2>Our Practice</h2>
                                    </div>
                                    <div className="practicecontent">
                                        <p>At Panikar’s dentestry, people come first. We help each of our patients to achieve optimal wellness and health by using a whole body approach to oral health. This means not just focusing on cavities, but focusing on; cranio-facial development, bite and joint balance, oral flora, proper muscle balance/function, and bio-compatibility of dental materials. Great care and planning ensure that everything we do helps promote overall health and well being.</p>
                                        <h3>More than anything else we love creating happy, healthy smiles.</h3>
                                        <p>We work hard to stay up to date with the most advanced techniques and technologies to ensure that our patients receive the best care possible. Our office utilizes 3D CBCT radiographs to allow for guided surgical and endodontic protocols. This enables these procedures to performed digitally before they are performed surgically to ensure optimal results. 3D imaging also is utilized for the analysis of airway growth and development. We also use the best 3D optical scanner for all of our dental restoration and Invisalign impressions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="practiceimage">
                                    <img src={Practice} alt="practice" />
                                </div>
                            </div>
                        </div>
                    </div>
               </section>
               <section className="meetourteam">
                    <div className="container">
                        <div className="meetteamhead">
                            <h2>Meet our specialists</h2>
                            <p>We use only the best quality materials on the market in order to provide the best treatments to our patients.</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4">
                                <div className="meetteamimg">
                                    <img src={Sreekumar} alt="Dr Sreekumar Panikar" />
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="meetteamcontent">
                                    <h3>Dr Sreekumar Panikar</h3>
                                    <p>Dr Sreekumar has completed advanced education in dental implants, tissue regeneration, full mouth rehabilitation, complex root canal therapy, surgical and non surgical gum treatments, laser dentistry, crown and bridge dentistry, dental photography, basic life support and other allied aspects of all round speciality dental practice.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-4">
                                <div className="meetteamimg">
                                    <img src={Sneha} alt="Dr Sneha Bharuka Panikar" />
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="meetteamcontent">
                                    <h3>Dr Sneha Bharuka Panikar</h3>
                                    <p>Dr Sneha Panikar is a Pediatric dentist in Panikar’s Centre for Advanced Dentistry serving families locally and in surrounding communities. She provides specialized dentistry for children and adolescents in a warm and kid-friendly atmosphere. We provide comprehensive pediatric dental care for children of all ages, including those with special health care needs, in a brand new state of the art dental facility.</p>
                                </div>
                            </div>
                        </div>
                    </div>
               </section>
               <section className="technologies">
                    <div className="container">
                        <div className="technologieshead">
                            <h2>Latest Technology</h2>
                            <p>Thanks to major technological advancements, dentistry allows treating the most complex cases with less time and more efficiency.</p>
                        </div>
                    </div>
                    <div className="container mt-45">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="technologiesimg">
                                    <img src={Technologies} alt="Technology" />
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="technologiescontent">
                                    <h3>The Future of Dentistry is Digital:</h3>
                                    <p>Dentists today already utilize software to capture insights in clinical decision-making. These practices will continue to develop to integrate AI algorithms that enable clinicians to find the best modalities for their patients.</p>
                                    <p>In the 21st century, digital radiographs and 3D imaging have become the standard of dental care. Using an intraoral scanner with digitized data for 3D dental impressions (vs. polyvinyl siloxane and rubber base impressions) for a dental crown is now commonplace.</p>
                                    <p>Artificial intelligence is laying the groundwork for the future of the dental industry. Dental robots can now perform functions such as filling cavities and cleaning or extracting teeth</p>
                                </div>
                            </div>
                        </div>
                    </div>
               </section>
            <Footer/>
        </React.Fragment>
    )
}