import React from "react";
import { Link } from "react-router-dom";
import "./blog.css";
import Blog1 from "../../Assets/blog.jpg";
import Blog2 from "../../Assets/blog1.jpg";
import Blog3 from "../../Assets/blog2.jpg";

export default function Blogs(){
    return(
        <React.Fragment>
            <section className="bloglist">
                <div className="container">
                    <div className="bloghead">
                        <h2>Blogs</h2>
                        <p>Explore our latest insights, expert tips, and updates in dental care.</p> <p>From innovative treatments to oral health advice, our blog keeps you informed and empowered on your journey to a healthier smile.</p>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                            <div className="bloggrid">
                                <div className="blogimg">
                                    <Link to="#"><img src={Blog1} alt="" /></Link>
                                </div>
                                <div className="blogcategory">
                                    <Link to="#">General Dentistry</Link>
                                </div>
                                <div className="blogtitle">
                                    <h2><Link to="#">Care of your Teeth</Link></h2>
                                </div>
                                <div className="blogcontent">
                                    <p>Lorem ipsum dolor sit amet consectetur.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                            <div className="bloggrid">
                                <div className="blogimg">
                                    <Link to="#"><img src={Blog2} alt="" /></Link>
                                </div>
                                <div className="blogcategory">
                                    <Link to="#">General Dentistry</Link>
                                </div>
                                <div className="blogtitle">
                                    <h2><Link to="#">Care of your Teeth</Link></h2>
                                </div>
                                <div className="blogcontent">
                                    <p>Lorem ipsum dolor sit amet consectetur.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                            <div className="bloggrid">
                                <div className="blogimg">
                                    <Link to="#"><img src={Blog3} alt="" /></Link>
                                </div>
                                <div className="blogcategory">
                                    <Link to="#">General Dentistry</Link>
                                </div>
                                <div className="blogtitle">
                                    <h2><Link to="#">Care of your Teeth</Link></h2>
                                </div>
                                <div className="blogcontent">
                                    <p>Lorem ipsum dolor sit amet consectetur.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
